import React from "react"
import ReactPlayer from "react-player"
import { useCookies } from "context/cookies"
import { parseYoutubeUrl } from "utils/youtube"

const YoutubeVideo = ({ url, className, autoplay = false, style, playerProps, ...props }) => {
    const [cookies] = useCookies([`rf-gdpr-youtube-embed`])

    const youtubeUrl = parseYoutubeUrl(url)
    if (youtubeUrl) {
        url = cookies[`rf-gdpr-youtube-embed`] === "true" ?
            youtubeUrl.embedUrl : youtubeUrl.cookielessEmbedUrl
    }

    return (
        <div className={'c-video ' + (className || '')}>
            <ReactPlayer
                url={url}
                width="100%"
                height="100%"
                playing={autoplay}
                controls={true}
                {...playerProps}
                config={{
                    ...playerProps?.config,
                    youtube: {
                        ...playerProps?.config?.youtube,
                        playerVars: {
                            ...playerProps?.config?.youtube?.playerVars,
                            modestbranding: 1,
                            enablejsapi: 1
                        }
                    }
                }}
            />
        </div>
    )
}
export default YoutubeVideo
