import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { useLocation } from "@reach/router"
import defaultImage from "assets/images/logo-secours-catholique.png"

function SEO({ page }) {
    const { pathname } = useLocation()
    const data = useStaticQuery(graphql`
        query {
            siteData: file(name: {eq: "site"}, sourceInstanceName: {eq: "site"}) {
                childContentYaml {
                    seo {
                        title
                        description
                    }
                }
            }
            blockHeadband: file(sourceInstanceName: {eq: "blocks"}, name: {eq: "home-headband"}) {
                childBlocksYaml {
                    image {
                        childImageSharp {
                            fluid(maxWidth: 570, quality: 100) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
            }
            site {
                siteMetadata {
                    title
                    description
                    siteLanguage
                    siteUrl
                    author
                    twitterHandle
                }
            }
        }
    `)
    const headbandImageUrl = data.blockHeadband?.childBlocksYaml?.image?.childImageSharp?.fluid?.src
    const metadata = data.site.siteMetadata
    const { seo } = data.siteData.childContentYaml

    const pageSEO = {
        url: `${metadata.siteUrl}${pathname}`,
        title: page?.title || seo?.title || metadata?.title || "",
        description: page?.description || seo?.description || metadata?.description || "",
        imageUrl: `${metadata.siteUrl}${page?.image || headbandImageUrl || defaultImage}`
    }

    let schemaOrgJSONLD = [
        {
            "@context": "http://schema.org",
            "@type": "WebSite",
            url: metadata.siteUrl,
            name: seo.title
        },
        {
            '@context': 'http://schema.org',
            '@type': 'WebPage',
            url: pageSEO.url,
            description: pageSEO.description,
            name: pageSEO.title,
            image: {
                '@type': 'ImageObject',
                url: pageSEO.imageUrl,
            },
        }
    ]

    return (
        <Helmet htmlAttributes={{ lang: metadata.siteLanguage }}>
            {/* General tags */}
            <title>{pageSEO.title}</title>
            <meta name="description" content={pageSEO.description} />
            <meta name="image" content={pageSEO.imageUrl} />
            <meta content="IE=edge" httpEquiv="X-UA-Compatible" />
            <meta name="viewport" content="width=device-width,initial-scale=1.0,user-scalable=yes" />
            <meta name="msapplication-TileColor" content="#ffffff" />
            <meta name="theme-color" content="#ffffff" />

            {/* Schema.org tags */}
            <script type="application/ld+json">{JSON.stringify(schemaOrgJSONLD)}</script>

            {/* OpenGraph tags */}
            <meta property="og:url" content={pageSEO.url} />
            <meta property="og:title" content={pageSEO.title} />
            <meta property="og:description" content={pageSEO.description} />
            <meta property="og:image" content={pageSEO.imageUrl} />
            <meta property="og:type" content="website" />

            {/* Twitter Card tags */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:creator" content={metadata.twitterHandle} />
            <meta name="twitter:title" content={pageSEO.title} />
            <meta name="twitter:description" content={pageSEO.description} />
            <meta name="twitter:image" content={pageSEO.imageUrl} />

            {/* Links */}
            {metadata.canonicalUrl && <link rel="canonical" href={pageSEO.url} />}
            <link rel="dns-prefetch" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="dns-prefetch" href="https://fonts.gstatic.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" />
        </Helmet>
    )
}

export default SEO