// @todo Remove this dependency when possible.
import getYouTubeID from 'get-youtube-id'

export const parseYoutubeUrl = url => {
    const youtubeId = getYouTubeID(url)
    return youtubeId && {
        id: youtubeId,
        cookielessEmbedUrl: `https://www.youtube-nocookie.com/embed/${youtubeId}`,
        embedUrl: `https://www.youtube.com/embed/${youtubeId}`,
    }
}